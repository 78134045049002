@import '~react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


.ReactTable .rt-thead .rt-resizable-header-content {
    display: inline-flex;
    align-items: center;
    padding-right: 20px;
    line-height: 20px;
}

button {
    outline: none !important
}

.MuiCheckbox-colorPrimary.Mui-checked {
    color: rgb(0, 172, 193) !important;
}

.MuiSwitch-colorPrimary.Mui-checked {
    color: rgb(0, 172, 193) !important;
}

.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: rgb(0, 172, 193) !important;
}

// @import '~bootstrap/dist/css/bootstrap.css';

.textColor {
    .makeStyles-input-779 {
        color: #999 !important;
    }
}

.rdw-dropdown-carettoopen{
    border-top: 6px solid white !important;
}

.rdw-dropdown-wrapper{
    a{
    color: white !important;
    }
    background: #00acc1 !important;
}
.rdw-editor-toolbar{
    background: #00acc1 !important;
}
.rdw-option-wrapper{
    filter: invert(1);
    background: none !important;
    border: none !important;
    border-radius: none !important;
}
.rdw-editor-wrapper{
    border: 2px solid #00acc1 !important;
}
.rdw-editor-main{
    padding: 0 5px !important;
    line-height: 0 !important;
}
.rdw-dropdown-carettoclose{
    filter: invert(1)
}
.clickable {
    color: #9c27b0;
    cursor: pointer;
}
// .rt-noData {
//     top: 70% !important;
// }
.ReactTable {
   // min-height: 400px;
}
